.table-responsive .shadow-sm thead {
    align-items: center;
    text-align: start;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
    color: #44444f;

    .dropdown-toggle::after {
        display: none !important;
    }

}

.table-responsive .shadow-sm tbody {
    font-size: 11px !important;
    letter-spacing: 0.8px;
    color: #44444f;
    align-items: center;

}

tbody .tbodyText {
    vertical-align: middle;
    font-size: 11px !important;

}

tr th div .headerItemTop {
    display: flex;
    align-items: start;
    justify-content: start;
    text-align: center;
    /* background-color: rgb(173, 173, 173); */
}

.headerText {
    white-space: nowrap;
}
.isSample {
    color: #92929d !important;
  }
.checBoxRow {
    display: flex;
    justify-content: center;
    align-items: center;
}
.checBoxRowSelectAll{
    margin-bottom: 15px;
}
.table-responsive .shadow-sm tbody tr:first-child {
    font-size: small;
}


.table-responsive .shadow-sm tbody tr input {
    width: 15px;
    height: 15px;
}
